import { api } from '@setplex/tria-api'
import { createStore, sample } from 'effector'
import { model as session } from '~/entities/session'
import { formatAppTheme } from './lib'

const getThemeFx = api.appearance.getColorSchemeFx

export const $appTheme = createStore<React.CSSProperties | null>(null)

sample({
  clock: session.$session,
  target: getThemeFx,
})

sample({
  clock: getThemeFx.doneData,
  fn: formatAppTheme,
  target: $appTheme,
})
